import { NgModule } from '@angular/core';
import { OnlyNumbersDirective } from './only-numbers.directive';
import { UpperCaseDirective } from './upper-case-directive.directive';
import { TryCurrencyFormatDirective } from './try-currency-format.directive';

@NgModule({
    declarations: [
        OnlyNumbersDirective,
        UpperCaseDirective,
        TryCurrencyFormatDirective,
    ],
    exports: [
        OnlyNumbersDirective,
        UpperCaseDirective
        
    ],
})
export class DirectivesModule { }
